<!-- eslint-disable no-unreachable -->
<template>
  <StatsLocalisationsTableComponent
    :table-datas="tableDatas"
    :is-loading-table-data="isLoadingTableData"
    title="Nombre moyen de nuitées par département / commune / arrondissements"
    :table-columns="tableColumns"
  />
</template>

<script>
import StatsLocalisationsTableComponent from '@/components/StatsLocalisationsTableComponent.vue'

import PaginationComponent from '@/components/PaginationComponent.vue'

import utilsService from '@/services/utils/utils.service'

const {
  departements, communes, arrondissements, quartiers,
} = utilsService.countriesAndPlaces({ stringOnly: false })

export default {
  components: {
    StatsLocalisationsTableComponent,
  },
  props: {
    tableDatas: {
      type: Object,
      default: () => ({
        departements: [],
        communes: [],
        arrondissements: [],
        villages: [],
      }),
    },
    isLoadingTableData: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      departements,
      communes,
      arrondissements,
      quartiers,
      selectedTypeLocalisation: 'departements',
      selectedTypeEtablissement: '',
      // isLoadingTableData: false,
      localSearchQuery: '',
      // tableDatas: {
      //   departements: [],
      //   communes: [],
      //   arrondissements: [],
      //   villages: [],
      // },
      filteredTableDatas: {
        departements: [],
        communes: [],
        arrondissements: [],
        villages: [],
      },
      typesLocalisation: [
        { name: 'Départements', key: 'departements' },
        { name: 'Communes', key: 'communes' },
        { name: 'Arrondissements', key: 'arrondissements' },
        // { name: 'Quartiers', key: 'quartiers' },
      ],
      chartData: {},

      tableColumns: [
        { key: 'name', label: 'Nom', sortable: true },
        {
          key: 'nombreMoyenNuitees',
          label: 'Nombre moyen de nuitées',
          sortable: true,
        },
        { key: 'pourcentageNombreMoyenNuitees', label: '%', sortable: true },
      ],
      typesEtablissementsOptions: [],
      paginationData: {
        perPage: 10,
        total: 0,
      },
    }
  },
  computed: {
    filterInputPlaceholder() {
      switch (this.selectedTypeLocalisation) {
        case 'departements':
          return 'Rechercher un département'
          break

        case 'communes':
          return 'Rechercher une commune'
          break
        case 'arrondissements':
          return 'Rechercher un arrondissement'
          break
        case 'quartiers':
          return 'Rechercher un quartier'
          break

        default:
          return 'Rechercher par départements, communes, arrondissements, quartiers'
          break
      }
    },
    getTableData() {
      const items = [...this.filteredTableDatas[this.selectedTypeLocalisation]]
      this.paginationData.total = items.length || 0
      const paginateArray = (array, perPage, page) => array.slice((page - 1) * perPage, page * perPage)
      const { perPage, currentPage } = this.paginationData
      // console.log(items, this.paginationData)
      return paginateArray(items, perPage, currentPage || 1)
      // return this.filteredTableDatas[this.selectedTypeLocalisation];
    },
    getTableColumsTitle() {
      return [
        {
          key: 'departement',
          label: 'Deoartement',
          sortable: true,
        },
        {
          key: '',
          label: "Nombre d'établissement",
          sortable: true,
        },
      ]
    },
  },
  watch: {
    localSearchQuery: {
      handler(val, old) {},
    },
    selectedTypeLocalisation: {
      handler(val, old) {
        this.filteredTableDatas[this.selectedTypeLocalisation] = this.tableDatas[this.selectedTypeLocalisation]
      },
    },
    tableDatas: {
      deep: true,
      immediate: true,
      handler(val, old) {
        this.filteredTableDatas[this.selectedTypeLocalisation] = val[this.selectedTypeLocalisation]
      },
    },
  },
  methods: {
    onPaginationChange(val) {
      // console.log('paginationChange', val)
      this.paginationData = { ...this.paginationData, ...val }
    },
    localFilter() {
      const query = this.localSearchQuery.toLowerCase()
      this.filteredTableDatas[this.selectedTypeLocalisation] = this.tableDatas[
        this.selectedTypeLocalisation
      ].filter(e => {
        if (!query) return true
        return e.name.toLowerCase().includes(query)
      })
      this.filteredTableDatas[this.selectedTypeLocalisation] = this.filteredTableDatas[this.selectedTypeLocalisation].filter(e => true)
    },
  },
}
</script>
