<template>
  <div>
    <b-card>
      <b-card-header>
        <b-card-title>Statistiques générales </b-card-title>
        <!-- {{filtersForm}} -->
      </b-card-header>
      <b-row>
        <b-col lg="12">
          <b-card>
            <div>
              <!-- <h4>Nombre de places disponibles</h4> -->
              <b-row>
                <b-col md="6">
                  <h5>Nombre total des entrées</h5>
                  <p class="text-primary">
                    <strong>
                      {{
                        (totalsValues &&
                          totalsValues.entrees) ||
                        0
                      }}
                    </strong>
                  </p>
                </b-col>
                <b-col md="6">
                  <h5>Nombre total des sorties</h5>
                  <p class="text-primary">
                    <strong>
                      {{
                        (totalsValues &&
                          totalsValues.sortie) ||
                        0
                      }}
                    </strong>
                  </p>
                </b-col>
              </b-row>
            </div>
            <hr />
          </b-card>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardTitle,
  BDropdown,
  BDropdownItem,
  BCardBody,
  BSpinner,
  BBadge,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";
import { mapActions, mapGetters, mapState } from "vuex";
import { ContentLoader } from "vue-content-loader";

const chartLabels = [];
const chartData = {
  lastDays: ["28 Derniers jours", "Mois dernier", "Annee Dernière"],
  listData: [
    {
      icon: "CircleIcon",
      iconColor: "text-primary",
      text: "Agences de voyage",
      result: 0,
    },
    {
      icon: "CircleIcon",
      iconColor: "text-warning",
      text: "Tour operator",
      result: 0,
    },
    {
      icon: "CircleIcon",
      iconColor: "text-danger",
      text: "Agence réceptive",
      result: 0,
    },
  ],
};
export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BDropdown,
    BDropdownItem,
    BCardBody,
    VueApexCharts,
    ContentLoader,
    BSpinner,
    BBadge,
  },
  data() {
    return {
      chartLabels,
      chartData,
      typesEtablissementPieChart: {
        series: [0, 0, 0],
        chartOptions: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          labels: chartLabels,
          dataLabels: {
            enabled: true,
          },
          legend: { show: false },
          stroke: {
            width: 4,
          },
          colors: [
            $themeColors.primary,
            $themeColors.warning,
            $themeColors.danger,
          ],
        },
      },
      isLoading: true,
      statisticsRequestData: null,
    };
  },
  async mounted() {
    // this.isLoading = false
    // await this.getStatistics();
  },
  methods: {
    ...mapActions("statistics", {
      action_getStatisticsAgencesVoyageToursOperators:
        "getStatisticsAgencesVoyageToursOperators",
      action_getStatisticsAeroports: "getStatisticsAeroports",
    }),
    async getStatistics() {
      this.isLoading = true;
      const fieldKey = "field";
      const typeEtablissementQueryId = "typeEtablissement";
      const queryParams = {
        tables: ["formaeroportentree", "formaeroportsortie"],
        filtre: this.filtersForm
      };

      await this.action_getStatisticsAeroports({
        params: queryParams,
      })
        .then((res) => {
          // console.log(res);
          const { data } = res;
          const { resources } = data;
          const { options, total } = resources;
          this.statisticsRequestData = resources;

          const { transportParEau, transportParRoute, transportParAvion } =
            total || {};
          const labels = [
            "Transport par eau",
            "Transport par route",
            "Transport par avion",
          ];
          const series = [
            transportParEau || 0,
            transportParRoute || 0,
            transportParAvion || 0,
          ];
          this.chartData.listData = labels.map((e, index) => {
            return {
              icon: "CircleIcon",
              iconColor: "text-danger",
              text: e,
              result: series[index],
            };
          });

          this.typesEtablissementPieChart.chartOptions.labels = labels;
          this.typesEtablissementPieChart.series = series;

          this.isLoading = false;

          // console.log('stats types ets', res)
        })
        .catch((error) => {
          this.isLoading = false;

          // console.log(error)
        });
    },
    processEtsCount(resources) {
      const { resourcesCount, valuesObjects } = resources || {};
      let result = [];
      if (
        Array.isArray(resourcesCount) &&
        Array.isArray(valuesObjects) &&
        resourcesCount.length === valuesObjects.length
      ) {
        // eslint-disable-next-line comma-spacing
        result = valuesObjects.map((e, i) => ({ [e.nom]: resourcesCount[i] }));
      }
      return result;
    },
  },
   watch: {
    hasSubmitFilterForm: {
      immediate: true,
      deep: true,
      handler(val, old) {
        /* 
        // Update statistics whenever filer is submitted
        */
        this.getStatistics();
      },
    },
    triggerFormValidations: {
      handler(val, old) {
        if (val === true) this.submitForm();
      },
    },
  },
  computed: {
    ...mapGetters('statistics', {
      filtersForm: 'getter_statisticsFiltersValues',
      hasSubmitFilterForm:"getter_hasSubmitFilterForm"
    }),
    totalsValues() {
      if (!this.statisticsRequestData || !this.statisticsRequestData.total)
        return null;
      return this.statisticsRequestData.total;
    },
  },
  created() {},
};
</script>
