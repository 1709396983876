<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>
        Statistiques sur les pays de provenance, de résidence et de destination
        des voyageurs à l'entrée et à la sortie des frontières terrestres
      </b-card-title>
      <!-- <b-dropdown
        text="Choisir une periode"
        variant="transparent"
        class="chart-dropdown"
        right
        no-caret
        toggle-class="p-0 mb-25"
        size="sm"
      >
        <b-dropdown-item
          v-for="day in chartData.lastDays"
          :key="day"
        >
          {{ day }}
        </b-dropdown-item>
      </b-dropdown> -->
    </b-card-header>

    <b-card-body>
      <div class="row">
        <div class="col-lg-12">
          <div v-append-export-button />

          <b-table
            responsive
            :items="getTableData"
            :fields="tableColumns"
            :busy="isLoadingTableData"
            show-empty
            empty-text="Aucune donnée trouvée"
            hover
          >
            <template #table-busy>
              <div class="text-center my-2">
                <b-spinner class="align-middle" />
              </div>
            </template>
            <template #cell(nombreVoyageursProvenance)="data">
              <div class="">
                <b-badge variant="light-success">
                  {{ data.item.nombreVoyageursProvenance }}
                </b-badge>
              </div>
            </template>
            <template #cell(nombreVoyageursResident)="data">
              <div class="">
                <b-badge variant="light-danger">
                  {{ data.item.nombreVoyageursResident }}
                </b-badge>
              </div>
            </template>
            <template #cell(nombreVoyageursDestination)="data">
              <div class="">
                <b-badge variant="light-success">
                  {{ data.item.nombreVoyageursDestination }}
                </b-badge>
              </div>
            </template>
          </b-table>

          <PaginationComponent
            :table-data="getTableData"
            :per-page="paginationData.perPage"
            :total-rows="paginationData.total"
            @change="onPaginationChange"
          />
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BDropdown,
  BDropdownItem,
  BCardBody,
  BTable,
  BFormSelect,
  BFormInput,
  BSpinner,
  BBadge,
} from "bootstrap-vue";
import { $themeColors } from "@themeConfig";
import { mapActions, mapState } from "vuex";
import utilsService from "@/services/utils/utils.service";
import PaginationComponent from "@/components/PaginationComponent.vue";

// store modules and vuex utilities
import etablissementsStroreModule from "@/store/etablissements";
import paramsStoreModule from "@/store/params";
import statisticsStoreModule from "@/store/statistics";

import {
  registerStoreModule,
  unregisterStoreModule,
} from "@/helpers/vuex-utils";

const listCountries = utilsService.getListCountries();

export default {
  components: {
    BSpinner,
    BCard,
    BCardHeader,
    BCardTitle,
    BDropdown,
    BDropdownItem,
    BCardBody,
    BTable,
    BFormSelect,
    BFormInput,
    BBadge,
    PaginationComponent,
  },
  setup() {
    const requiredStoreModules = [
      { path: "etablissements", module: etablissementsStroreModule },
      { path: "params", module: paramsStoreModule },
      { path: "statistics", module: statisticsStoreModule },
    ];

    // Register module
    registerStoreModule(requiredStoreModules);

    return { requiredStoreModules };
  },
  data() {
    return {
      paginationData: {
        perPage: 10,
        total: 0,
      },
      listCountries,
      isLoadingTableData: false,
      tableDatas: {
        departements: [],
        communes: [],
        arrondissements: [],
        villages: [],
      },
      tableColumns: [
        { key: "name", label: "Pays", sortable: true },
        {
          key: "nombreVoyageursProvenance",
          label: "Nombre de voyageurs en provenance de ce pays",
          sortable: true,
        },
        {
          key: "nombreVoyageursResident",
          label: "Nombre de voyageurs résidents dans ce pays",
          sortable: true,
        },
        {
          key: "nombreVoyageursDestination",
          label: "Nombre de voyageurs en destination de ce pays",
          sortable: true,
        },
      ],
    };
  },
  watch: {
    tableDatas: {
      deep: true,
      immediate: true,
      handler(val, old) {
        // this.filteredTableDatas[this.selectedTypeLocalisation] = val[this.selectedTypeLocalisation]
      },
    },
  },
  beforeDestroy() {
    unregisterStoreModule(this.requiredStoreModules);
  },
  async mounted() {
    await this.getStatisticsAgencesVoyageToursOperators();
  },
  methods: {
    ...mapActions("params", {
      action_loadDepartments: "loadDepartments",
      action_loadCommunes: "loadCommunes",
      action_loadArrondissements: "loadArrondissements",
      action_loadNeighborhoods: "loadNeighborhoods",
    }),
    ...mapActions("statistics", {
      action_getStatisticsAgencesVoyageToursOperators:
        "getStatisticsAgencesVoyageToursOperators",
    }),
    ...mapActions("etablissements", {
      action_getListTypesEtablissement: "getListTypesEtablissement",
    }),
    onPaginationChange(val) {
      // console.log("paginationChange", val);
      this.paginationData = { ...this.paginationData, ...val };
    },
    localFilter() {
      // const query = this.localSearchQuery.toLowerCase()
      // this.filteredTableDatas[this.selectedTypeLocalisation] = this.tableDatas[
      //   this.selectedTypeLocalisation
      // ].filter(e => {
      //   if (!query) return true
      //   return e.name.toLowerCase().includes(query)
      // })
      // this.filteredTableDatas[this.selectedTypeLocalisation] = this.filteredTableDatas[this.selectedTypeLocalisation].filter(
      //   e => true,
      // )
    },
    getRandomInt(min = 10, max = 1000) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
    async getStatisticsAgencesVoyageToursOperators() {
      this.isLoadingTableData = true;
      const fieldKey = "field";
      const typeEtablissementQueryId = "typeEtablissement";
      const secteurQueryId = "secteur";
      const departementQueryId = "departement";
      const communeQueryId = "commune";
      const arrondissementQueryId = "arrondissement";
      const quartierQueryId = "quartier";
      const queryParams = {
        fields: [
          "_id",
          departementQueryId,
          communeQueryId,
          arrondissementQueryId,
          quartierQueryId,
          secteurQueryId,
          typeEtablissementQueryId,
        ],
      };
      await this.action_getStatisticsAgencesVoyageToursOperators({
        params: queryParams,
      })
        .then(async (res) => {
          this.listCountries = this.listCountries.map((e) => {
            const temp = {
              nombreVoyageursProvenance: this.getRandomInt(),
              nombreVoyageursResident: this.getRandomInt(),
              nombreVoyageursDestination: this.getRandomInt(),
            };
            return { ...e, ...temp };
          });
          // await this.loadDepartments()
          // await this.loadCommunes()
          // await this.loadArrondissements()
          // await this.loadNeighborhoods()
          // await this.getListTypesEtablissement()
          // const { data } = res
          // const { resources } = data
          // const departements = this.departements.map(e => ({
          //   name: e.name, nbrEts: 0,
          // }))

          // const communes = this.communes.map(e => ({
          //   name: e.name, nbrEts: 0,
          // }))
          // const arrondissements = this.arrondissements.map(e => ({
          //   name: e.name, nbrEts: 0,
          // }))
          // const quartiers = this.quartiers.map(e => ({
          //   name: e.name, nbrEts: 0,
          // }))
          // if (resources && Array.isArray(resources)) {
          //   // departements
          //   const statsDepartements = resources.find(e => e[fieldKey] === departementQueryId)
          //   const statsDepartementsValues = this.processEtsCount(statsDepartements)
          //   statsDepartementsValues.forEach(element => {
          //     const itemKey = Object.keys(element)[0]
          //     const itemValue = Object.values(element)[0]
          //     const foundIndex = departements.findIndex(e => e.name === itemKey)
          //     if (foundIndex !== -1) departements[foundIndex].nbrEts = itemValue
          //   })
          //   this.tableDatas[`${departementQueryId}s`] = departements

          //   // communes
          //   const statsCommunes = resources.find(e => e[fieldKey] === communeQueryId)
          //   const statsCommunesValues = this.processEtsCount(statsCommunes)
          //   statsCommunesValues.forEach(element => {
          //     const itemKey = Object.keys(element)[0]
          //     const itemValue = Object.values(element)[0]
          //     const foundIndex = communes.findIndex(e => e.name === itemKey)
          //     if (foundIndex !== -1) communes[foundIndex].nbrEts = itemValue
          //   })
          //   this.tableDatas[`${communeQueryId}s`] = communes

          //   // arrondissements
          //   const statsArrondissements = resources.find(e => e[fieldKey] === arrondissementQueryId)
          //   const statsArrondissementsValues = this.processEtsCount(statsArrondissements)
          //   statsArrondissementsValues.forEach(element => {
          //     const itemKey = Object.keys(element)[0]
          //     const itemValue = Object.values(element)[0]
          //     const foundIndex = arrondissements.findIndex(e => e.name === itemKey)
          //     if (foundIndex !== -1) arrondissements[foundIndex].nbrEts = itemValue
          //   })
          //   this.tableDatas[`${arrondissementQueryId}s`] = arrondissements

          //   // quarters
          //   const statsQuartiers = resources.find(e => e[fieldKey] === arrondissementQueryId)
          //   const statsQuartiersValues = this.processEtsCount(statsQuartiers)
          //   statsQuartiersValues.forEach(element => {
          //     const itemKey = Object.keys(element)[0]
          //     const itemValue = Object.values(element)[0]
          //     const foundIndex = quartiers.findIndex(e => e.name === itemKey)
          //     if (foundIndex !== -1) quartiers[foundIndex].nbrEts = itemValue
          //   })
          //   this.tableDatas[`${quartierQueryId}s`] = quartiers
          // }
          this.isLoadingTableData = false;

          // console.log('stats', res)
        })
        .catch((error) => {
          this.isLoadingTableData = false;

          // console.log(error)
        });
    },
    async loadDepartments() {
      this.isLoadingDepartements = true;
      await this.action_loadDepartments()
        .then((res) => {
          this.departements = res;
          // this.departementsPie.chartOptions.labels = res
          //   .map(e => e.name)
          //   .filter(e => true)
          // this.departementsPie.series = res.map(e => this.getRandomInt())
          // const departements = res.map(e => ({
          //   name: e.name,
          //   "Nombre d'établissement": this.getRandomInt(),
          // }))

          // this.tableDatas.departements = departements

          this.isLoadingDepartements = false;
        })
        .catch((err) => {
          this.isLoadingDepartements = false;
        });
    },
    async loadCommunes() {
      this.isLoadingCommunes = true;

      await this.action_loadCommunes()
        .then((res) => {
          this.communes = res;
          // this.communesPie.chartOptions.labels = res
          //   .map(e => e.name)
          //   .filter(e => true)
          // this.communesPie.series = res.map(e => this.getRandomInt())

          // const communes = res.map(e => ({
          //   name: e.name,
          //   "Nombre d'établissement": this.getRandomInt(),
          // }))
          // this.tableDatas.communes = communes
          this.isLoadingCommunes = false;
        })
        .catch((err) => {
          this.isLoadingCommunes = false;
        });
    },
    async loadArrondissements() {
      this.isLoadingArrondissements = true;

      await this.action_loadArrondissements()
        .then((res) => {
          this.arrondissements = res;
        })
        .catch((err) => {
          this.isLoadingArrondissements = false;
        });
    },
    async loadNeighborhoods() {
      this.isLoadingQuartiers = true;

      await this.action_loadNeighborhoods()
        .then((res) => {
          // console.log(res)
          this.quartiers = res;
          // const quartiers = res.map(e => ({
          //   name: e.name,
          //   "Nombre d'établissement": this.getRandomInt(),
          // }))
          // this.tableDatas.quartiers = quartiers
          this.isLoadingQuartiers = false;
        })
        .catch((err) => {
          this.isLoadingQuartiers = false;
        });
    },
    processEtsCount(resources) {
      const { resourcesCount, values } = resources || {};
      let result = [];
      if (
        Array.isArray(resourcesCount) &&
        Array.isArray(values) &&
        resourcesCount.length === values.length
      ) {
        // eslint-disable-next-line comma-spacing
        result = values.map((e, i) => ({ [e]: resourcesCount[i] }));
      }
      return result;
    },
  },
  computed: {
    getTableData() {
      const items = [...this.listCountries];
      this.paginationData.total = items.length || 0;
      const paginateArray = (array, perPage, page) =>
        array.slice((page - 1) * perPage, page * perPage);
      const { perPage, currentPage } = this.paginationData;
      // console.log(items, this.paginationData);
      return paginateArray(items, perPage, currentPage || 1);
      return this.filteredTableDatas[this.selectedTypeLocalisation];
    },
  },
};
</script>
