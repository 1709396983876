<!-- eslint-disable no-unreachable -->
<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>
        Nombre d'établissements hôteliers par type (auberge, hôtel, etc.)
      </b-card-title>
      <!-- <b-dropdown
        text="Choisir une periode"
        variant="transparent"
        class="chart-dropdown"
        right
        no-caret
        toggle-class="p-0 mb-25"
        size="sm"
      >
        <b-dropdown-item
          v-for="day in chartData.lastDays"
          :key="day"
        >
          {{ day }}
        </b-dropdown-item>
      </b-dropdown> -->
    </b-card-header>

    <b-card-body>
      <div class="row">
        <div class="col-lg-12">
          <!-- <h5>
            Liste du nombre d'établissements</h5> -->

          <div v-if="false" class="mb-2">
            <div class="mb-1">Filtrer par</div>
            <div class="row">
              <div class="col">
                <b-form-select
                  v-model="selectedTypeLocalisation"
                  :options="typesLocalisation"
                  html-field="name"
                  value-field="key"
                />
              </div>
              <div class="col">
                <b-form-input
                  v-model="localSearchQuery"
                  :placeholder="filterInputPlaceholder"
                  @keyup="localFilter"
                />
              </div>
            </div>
          </div>
          <div v-append-export-button />

          <b-table
            responsive
            :items="getTableData"
            :fields="tableColumns"
            :busy="isLoadingTableData"
            hover
          >
            <template #thead-top="data">
              <b-tr>
                <b-th />
                <b-th colspan="8" class="text-center">
                  Types d'établissement
                </b-th>
              </b-tr>
            </template>
            <template #table-busy>
              <div class="text-center my-2">
                <b-spinner class="align-middle" />
              </div>
            </template>
            <!-- <template v-for="(types, index) in listTypesEtablissementsHebergement" :key="index" #cell(turnToSnakeCase(type))>
              <div>

              </div>
            </template> -->
            <!-- <template
              v-for="(type, index) in listTypesEtablissementsHebergement"
              #cell(turnToSnakeCase(type))="data"
            >

              {{data.item.intersectionItems.find(e=>e.typeEtsId===turnToSnakeCase(type))}}
            </template> -->
          </b-table>
        </div>
      </div>

      <!-- list group -->
      <div v-if="false" class="pt-25">
        <div
          v-for="(data, index) in chartData.listData"
          :key="index"
          class="d-flex justify-content-between"
          :class="
            index === Object.keys(chartData.listData).length - 1 ? '' : 'mb-1'
          "
        >
          <div class="series-info">
            <feather-icon
              :icon="data.icon"
              size="16"
              class="mr-50"
              :class="data.iconColor"
            />
            <span class="font-weight-bolder">{{ data.text }}</span>
          </div>
          <span>{{ data.result }}</span>
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BDropdown,
  BDropdownItem,
  BCardBody,
  BTable,
  BFormSelect,
  BFormInput,
  BSpinner,
  BBadge,
  BTh,
  BTr,
} from "bootstrap-vue";
import { $themeColors } from "@themeConfig";
import { mapActions, mapState } from "vuex";
import PaginationComponent from "@/components/PaginationComponent.vue";

import utilsService from "@/services/utils/utils.service";
import {
  classementEtablissementHebergement,
  listTypesEtablissementsHebergement,
} from "@/services/utils/constants";

// store module and vuex utilities
import etablissementsStroreModule from "@/store/etablissements";
import secteursStoreModule from "@/store/secteurs";
import {
  registerStoreModule,
  unregisterStoreModule,
} from "@/helpers/vuex-utils";

const { departements, communes, arrondissements, quartiers } =
  utilsService.countriesAndPlaces({ stringOnly: false });

const { turnToSnakeCase } = utilsService;
const rankList = classementEtablissementHebergement.map((e) => ({
  id: turnToSnakeCase(e),
  label: e,
  intersectionItems: new Array(7),
}));

export default {
  components: {
    PaginationComponent,
    BSpinner,
    BCard,
    BCardHeader,
    BCardTitle,
    BDropdown,
    BDropdownItem,
    BCardBody,
    BTable,
    BFormSelect,
    BFormInput,
    BBadge,
    BTh,
    BTr,
  },
  props: {
    tableDatas: {
      type: Object,
      default: () => ({
        departements: [],
        communes: [],
        arrondissements: [],
        villages: [],
      }),
    },
    statsDatas: {
      type: Object,
      default: () => ({
        classements: rankList,
      }),
    },
    isLoadingTableData: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const requiredStoreModules = [
      { path: "etablissements", module: etablissementsStroreModule },
      { path: "secteurs", module: secteursStoreModule },
    ];
    // Register module
    registerStoreModule(requiredStoreModules);

    return {
      requiredStoreModules,
    };
  },

  data() {
    return {
      rankList,
      typesEtablissementsHoteliers: listTypesEtablissementsHebergement,
      departements,
      communes,
      arrondissements,
      quartiers,
      selectedTypeLocalisation: "departements",
      selectedTypeEtablissement: "",
      // isLoadingTableData: false,
      localSearchQuery: "",
      // tableDatas: {
      //   departements: [],
      //   communes: [],
      //   arrondissements: [],
      //   villages: [],
      // },
      filteredTableDatas: {
        departements: [],
        communes: [],
        arrondissements: [],
        villages: [],
      },
      typesLocalisation: [
        { name: "Départements", key: "departements" },
        { name: "Communes", key: "communes" },
        { name: "Arrondissements", key: "arrondissements" },
        // { name: 'Quartiers', key: 'quartiers' },
      ],
      chartData: {},
      statsData: {},

      tableColumns: [
        {
          key: "label",
          label: "Classement",
          sortable: true,
        },
        // {
        //   key: 'nombreMoyenNuitees',
        //   label: 'Nombre moyen de nuitées',
        //   sortable: true,
        // },
        // { key: 'pourcentageNombreMoyenNuitees', label: '%', sortable: true },
        ...listTypesEtablissementsHebergement.map((e) => ({
          key: turnToSnakeCase(e),
          label: e,
          sortable: true,
        })),
      ],
      typesEtablissementsOptions: [],
      paginationData: {
        perPage: 10,
        total: 0,
      },
    };
  },
  computed: {
    filterInputPlaceholder() {
      switch (this.selectedTypeLocalisation) {
        case "departements":
          return "Rechercher un département";
          break;

        case "communes":
          return "Rechercher une commune";
          break;
        case "arrondissements":
          return "Rechercher un arrondissement";
          break;
        case "quartiers":
          return "Rechercher un quartier";
          break;

        default:
          return "Rechercher par départements, communes, arrondissements, quartiers";
          break;
      }
    },
    getTableData() {
      return this.rankList;
    },
    getTableColumsTitle() {
      return [
        {
          key: "departement",
          label: "Deoartement",
          sortable: true,
        },
        {
          key: "",
          label: "Nombre d'établissement",
          sortable: true,
        },
      ];
    },
  },
  watch: {
    localSearchQuery: {
      handler(val, old) {},
    },
    selectedTypeLocalisation: {
      handler(val, old) {
        this.filteredTableDatas[this.selectedTypeLocalisation] =
          this.tableDatas[this.selectedTypeLocalisation];
      },
    },
    statsDatas: {
      deep: true,
      immediate: true,
      handler(val, old) {
        // console.log('val :>> ', val)
        if (val) {
          const { classements, typesEtablissementsHebergement } = val;
          this.rankList = [...classements];
          // this.typesEtablissementsHoteliers = [...typesEtablissementsHoteliers]
          // // this.rankList = this.rankList.filter(e => true)
        }
        // this.filteredTableDatas[this.selectedTypeLocalisation] = val[this.selectedTypeLocalisation]
      },
    },
  },
  mounted() {
    // this.getListTypesEtablissement()
  },
  beforeDestroy() {
    unregisterStoreModule(this.requiredStoreModules);
  },
  methods: {
    ...mapActions("etablissements", {
      action_getListTypesEtablissement: "getListTypesEtablissement",
    }),
    getListTypesEtablissement() {
      this.action_getListTypesEtablissement()
        .then((res) => {
          this.typesEtablissementsHoteliers =
            ((res.data.resources || []).find((e) => e.code === "hotels") || {})
              .sousTypes || [];
          // console.log(
          //   'typesEtablissementsHoteliers',
          //   this.typesEtablissementsHoteliers,
          // )
        })
        .catch((err) => {});
    },
    onPaginationChange(val) {
      // console.log('paginationChange', val)
      this.paginationData = { ...this.paginationData, ...val };
    },
    localFilter() {
      const query = this.localSearchQuery.toLowerCase();
      this.filteredTableDatas[this.selectedTypeLocalisation] = this.tableDatas[
        this.selectedTypeLocalisation
      ].filter((e) => {
        if (!query) return true;
        return e.name.toLowerCase().includes(query);
      });
      this.filteredTableDatas[this.selectedTypeLocalisation] =
        this.filteredTableDatas[this.selectedTypeLocalisation].filter(
          (e) => true
        );
    },
  },
};
</script>
